import 'react-dates/initialize'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DayPickerSingleDateController, isInclusivelyAfterDay } from 'react-dates'
import { Container } from './DatePicker.style'
import { MonthNavigationButton } from '../DateInput/MonthNavigationButton'
import { DatePickerProps } from './DatePicker.types'
import { DEFAULT_DAY_SIZE, DEFAULT_LOCALE, overriddenPhrases } from '../DateInput/DateInput'

export const DatePicker: React.FC<DatePickerProps> = ({
  locale,
  prevMonthButtonAriaLabel,
  nextMonthButtonAriaLabel,
  ...props
}) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    moment.locale(locale)
    // Hide and show date picker to make locale update properly
    setShow(false)
  }, [locale])

  useEffect(() => {
    !show && setShow(true)
  }, [show])

  return (
    <Container singleDatePicker={true} daySize={props.daySize} numberOfMonths={props.numberOfMonths} disabled={false}>
      {show && (
        <DayPickerSingleDateController
          hideKeyboardShortcutsPanel={true}
          noBorder={true}
          navPrev={<MonthNavigationButton orientation={'left'} aria-label={prevMonthButtonAriaLabel} />}
          navNext={<MonthNavigationButton orientation={'right'} aria-label={nextMonthButtonAriaLabel} />}
          horizontalMonthPadding={0}
          focused={true}
          onFocusChange={() => {}}
          phrases={overriddenPhrases}
          {...props}
        />
      )}
    </Container>
  )
}

DatePicker.defaultProps = {
  daySize: DEFAULT_DAY_SIZE,
  numberOfMonths: 1,
  // Past dates are disabled by default like in DateSinglePicker
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  locale: DEFAULT_LOCALE,
}

DatePicker.propTypes = {
  locale: PropTypes.string,
}
