import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'

interface Props extends DefaultIconProps {
  disabled?: boolean
}

const BadFeedbackIcon: React.FC = (props: Props) => (
  <SVG {...props}>
    <Path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill={XyzTheme.color.neutralGray5}
    />
    <Path
      d="M8.19828 6.34645C8.19828 5.90831 8.55345 5.55313 8.99159 5.55313C9.42972 5.55313 9.7849 5.90831 9.7849 6.34645V8.46195C9.7849 8.90008 9.42972 9.25526 8.99159 9.25526C8.55345 9.25526 8.19828 8.90008 8.19828 8.46195V6.34645Z"
      fill={props.disabled ? XyzTheme.color.neutralPassiveGray : XyzTheme.color.neutralNetworkGray}
      stroke={props.disabled ? XyzTheme.color.neutralPassiveGray : XyzTheme.color.neutralNetworkGray}
      strokeWidth="0.528875"
    />
    <Path
      d="M13.487 6.34645C13.487 5.90831 13.8422 5.55313 14.2803 5.55313C14.7184 5.55313 15.0736 5.90831 15.0736 6.34645V8.46195C15.0736 8.90008 14.7184 9.25526 14.2803 9.25526C13.8422 9.25526 13.487 8.90008 13.487 8.46195V6.34645Z"
      fill={props.disabled ? XyzTheme.color.neutralPassiveGray : XyzTheme.color.neutralNetworkGray}
      stroke={props.disabled ? XyzTheme.color.neutralPassiveGray : XyzTheme.color.neutralNetworkGray}
      strokeWidth="0.528875"
    />
    <Path
      d="M6.63362 15.3429C7.3378 13.6471 9.32416 11.8996 11.6359 11.8996C13.9477 11.8996 15.9341 13.6471 16.6382 15.3429C16.6663 15.4104 16.6625 15.4554 16.6542 15.4825C16.6455 15.511 16.6258 15.539 16.5919 15.5617C16.5222 15.6085 16.3988 15.6258 16.2751 15.5494C15.3071 14.9517 13.7136 14.2353 11.6359 14.2353C9.55822 14.2353 7.96473 14.9516 6.99674 15.5494C6.87303 15.6258 6.74963 15.6085 6.67994 15.5617C6.64604 15.539 6.62637 15.511 6.61765 15.4825C6.60939 15.4554 6.60558 15.4104 6.63362 15.3429Z"
      fill={props.disabled ? XyzTheme.color.neutralPassiveGray : XyzTheme.color.neutralNetworkGray}
      stroke={props.disabled ? XyzTheme.color.neutralPassiveGray : XyzTheme.color.neutralNetworkGray}
      strokeWidth="0.528875"
    />
  </SVG>
)

BadFeedbackIcon.defaultProps = {
  viewBox: '0 0 24 21',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
  disabled: false,
}

export { BadFeedbackIcon }
