import React from 'react'
import styled from 'styled-components'
import { XyzTheme } from '@postidigital/posti-theme'
import { Headline, HeadlineSize } from '../../typography'
import { CompanyLogoIconProps, IconSize } from '../icons.types'
import { CompanyLogoBase } from './CompanyLogoBase'

const initialFontSize = {
  [IconSize.lg]: HeadlineSize.Five,
  [IconSize.md]: HeadlineSize.Six,
  [IconSize.sm]: HeadlineSize.Seven,
}

const Initials = styled(Headline)`
  text-transform: uppercase;
  color: ${XyzTheme.color.neutralNetworkGray};
  font-weight: ${XyzTheme.fontWeight.semiBold};
  line-height: 1;
  margin-bottom: -1px;
`

const CompanyLogoIcon: React.FC<CompanyLogoIconProps> = ({ initial, icon, size, ...props }) => {
  // if whole company name is passed, first letter will be used
  const initialFormatted = initial[0] ?? ''
  return (
    <CompanyLogoBase
      size={size}
      icon={icon || <Initials size={initialFontSize[size]}>{initialFormatted}</Initials>}
      {...props}
    />
  )
}

export { CompanyLogoIcon }
