import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'

const ParcelXXLPlusIcon: React.FC<DefaultIconProps> = (props) => (
  <SVG {...props}>
    <Path
      d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
      fill={XyzTheme.color.brandCardboard}
    />
    <Path
      d="M10.8232 25L8.73715 22.368L6.87515 25H3.45915L7.11315 20.338L3.41715 15.69H6.86115L8.91915 18.266L10.7532 15.69H14.1272L10.5432 20.296L14.3092 25H10.8232ZM22.3485 25L20.2625 22.368L18.4005 25H14.9845L18.6385 20.338L14.9425 15.69H18.3865L20.4445 18.266L22.2785 15.69H25.6525L22.0685 20.296L25.8345 25H22.3485ZM33.3905 22.788V25H26.5445V15.69H29.3865V22.788H33.3905Z"
      fill={XyzTheme.color.brandForest}
    />
    <Path
      d="M40.789 16.4824H37.3556V20.0287H34.2836V16.4824H30.8501V13.6136H34.2836V10.0673H37.3556V13.6136H40.789V16.4824Z"
      fill={XyzTheme.color.brandForest}
    />
  </SVG>
)

ParcelXXLPlusIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelXXLPlusIcon }
