import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { DefaultIconProps } from '../icons.types'
import { SVG, Rect, Path } from '../icons.styled'

export const LockerPinIcon: React.FC<DefaultIconProps> = (props) => (
  <SVG {...props}>
    <ellipse cx="20" cy="46" rx="8" ry="2" fill={XyzTheme.color.neutralPassiveGray} />
    <Rect width="40" height="40" rx="2" fill={XyzTheme.color.brandPetrol} />
    <Path d="M25 40L20 46L15 40H25Z" fill={XyzTheme.color.brandPetrol} />
    <Path
      fillRule="evenodd"
      d="M22 12H10V17L22 17V12ZM23 12V20L30 20V12H23ZM30 21H23V28H30V21ZM22 28V24L10 24V28H22ZM10 23L22 23V18L10 18V23ZM25 14V18H26V17H27C27.5523 17 28 16.5523 28 16V15C28 14.4477 27.5523 14 27 14H25ZM27 15V16H26V15H27Z"
      fill={XyzTheme.color.brandBrightYellow}
    />
  </SVG>
)

LockerPinIcon.defaultProps = {
  viewBox: '0 0 40 48',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}
