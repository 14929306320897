import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'

const ParcelLargeIcon: React.FC<DefaultIconProps> = (props) => (
  <SVG {...props}>
    <Path
      d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
      fill={XyzTheme.color.brandNavy}
    />
    <Path d="M29.356 24.969V29H15.523V9.715H20.685V24.969H29.356Z" fill={XyzTheme.color.brandPeach} />
  </SVG>
)

ParcelLargeIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelLargeIcon }
