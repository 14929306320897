import React from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'
import { StyledButton } from './DateInput.style'
import { MonthNavigationButtonProps } from './DateInput.types'

export const MonthNavigationButton: React.FC<MonthNavigationButtonProps> = ({ orientation, ...props }) => {
  const theme = useTheme()
  const isLeft = orientation === 'left'

  return (
    <StyledButton
      isLeft={isLeft}
      icon={isLeft ? ArrowLeftIcon : ArrowRightIcon}
      iconColor={theme.xyz.color.neutralIconGray}
      {...props}
    />
  )
}
