import React from 'react'
import { SVG, Path, Circle } from '../icons.styled'
import { defaultIndicatorIconProps, IndicatorIconProps, indicatorIconPropTypes } from '../icons.types'
import { XyzTheme } from '@postidigital/posti-theme'

const InfoIndicatorIcon: React.FC<IndicatorIconProps> = ({ color, inverted, ...props }) =>
  inverted ? (
    <SVG {...props} viewBox="0 0 16 16" width={`${XyzTheme.iconSize.xs}em`} height={`${XyzTheme.iconSize.xs}em`}>
      <Path
        fillRule="evenodd"
        d="M15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8ZM9 5C9 5.55228 8.55229 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5ZM8.75 12V7H7.25V12H8.75Z"
        fill={color}
      />
    </SVG>
  ) : (
    <SVG
      {...props}
      viewBox="0 0 16 16"
      width={`${XyzTheme.iconSize.xs}em`}
      height={`${XyzTheme.iconSize.xs}em`}
      fill="none"
    >
      <Circle cx="8" cy="8" r="7.25" stroke={color} />
      <Circle cx="8" cy="5" r="1" fill={color} />
      <Path fill={color} d="M7.25 7H8.75V12H7.25V7Z" />
    </SVG>
  )

InfoIndicatorIcon.defaultProps = {
  ...defaultIndicatorIconProps,
  color: XyzTheme.color.signalBlue,
}

InfoIndicatorIcon.propTypes = indicatorIconPropTypes

export { InfoIndicatorIcon }
