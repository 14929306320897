import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'
import { XyzThemeColor } from '../../../utils/helpers'

export enum HeadlineSize {
  /**
   * 80px
   */
  One = 'One',
  /**
   * 48px
   */
  Two = 'Two',
  /**
   * 39px
   */
  Three = 'Three',
  /**
   * 31.5px
   */
  Four = 'Four',
  /**
   * 25px
   */
  Five = 'Five',
  /**
   * 18px
   */
  Six = 'Six',
  /**
   * 16px
   */
  Seven = 'Seven',
  /**
   * 14px
   */
  Eight = 'Eight',
}

export interface HeadlineProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * The size of the headline. Use the exported enum HeadlineSize for the values or just a
   * plain string matching one of the keys
   */
  size: HeadlineSize | keyof typeof HeadlineSize
  color?: XyzThemeColor | string
}

export const Headline = styled.div<HeadlineProps>(
  ({ theme, size, color }) => css`
    ${size === HeadlineSize.One &&
    `
      font-family: ${theme.xyz.fontFamily.Telegraf};
      font-weight: ${theme.xyz.fontWeight.ultraBold};
      font-size: ${theme.xyz.fontSize.headline.one}rem;
      line-height: ${theme.xyz.lineHeight.headline.one};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralBlack};
      `}
    ${size === HeadlineSize.Two &&
    `
      font-family: ${theme.xyz.fontFamily.Telegraf};
      font-weight: ${theme.xyz.fontWeight.ultraBold};
      font-size: ${theme.xyz.fontSize.headline.two}rem;
      line-height: ${theme.xyz.lineHeight.headline.two};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralBlack};
      `}
    
    ${size === HeadlineSize.Three &&
    `
      font-family: ${theme.xyz.fontFamily.Telegraf};
      font-weight: ${theme.xyz.fontWeight.ultraBold};
      font-size: ${theme.xyz.fontSize.headline.three}rem;
      line-height: ${theme.xyz.lineHeight.headline.three};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralBlack};
      `}
    
    ${size === HeadlineSize.Four &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.bold};
      font-size: ${theme.xyz.fontSize.headline.four}rem;
      line-height: ${theme.xyz.lineHeight.headline.four};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralNetworkGray};
      `}    
    
    ${size === HeadlineSize.Five &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.bold};
      font-size: ${theme.xyz.fontSize.headline.five}rem;
      line-height: ${theme.xyz.lineHeight.headline.five};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralNetworkGray};
      `}
    
    ${size === HeadlineSize.Six &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.semiBold};
      font-size: ${theme.xyz.fontSize.headline.six}rem;
      line-height: ${theme.xyz.lineHeight.headline.six};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralNetworkGray};
      `}                   
    
    ${size === HeadlineSize.Seven &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.semiBold};
      font-size: ${theme.xyz.fontSize.headline.seven}rem;
      line-height: ${theme.xyz.lineHeight.headline.seven};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralNetworkGray};
      `}           
    
    ${size === HeadlineSize.Eight &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.medium};
      font-size: ${theme.xyz.fontSize.headline.eight}rem;
      line-height: ${theme.xyz.lineHeight.headline.eight};
      color: ${theme[color] ?? color ?? theme.xyz.color.neutralNetworkGray};
      `}
  `
)

Headline.propTypes = {
  size: PropTypes.oneOf(Object.keys(HeadlineSize)).isRequired as Validator<HeadlineSize>,
  color: PropTypes.string,
}
