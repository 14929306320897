import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import PropTypes from 'prop-types'

interface Props extends DefaultIconProps {
  isColor: boolean
}

const ThumbsDownIcon: React.FC<Props> = (props) => (
  <SVG {...props}>
    <Path
      d="M0 10.5C0 5.36862 2.87838 0 12 0C21.1216 0 24 5.36862 24 10.5C24 15.6318 21.1216 21 12 21C2.87838 21.0004 0 15.6712 0 10.5Z"
      fill={props.isColor ? XyzTheme.color.signalLightRed : XyzTheme.color.neutralGray5}
    />
    <Path
      d="M17.357 12.3779L18.4285 12.3779C19.0203 12.3779 19.5 12.012 19.5 11.5607L19.5 6.65701C19.5 6.20564 19.0203 5.83973 18.4285 5.83973L17.357 5.83973C16.7652 5.83973 16.2855 6.20564 16.2855 6.65701L16.2855 11.5607C16.2855 12.012 16.7652 12.3779 17.357 12.3779Z"
      fill={props.isColor ? XyzTheme.color.brandRed : XyzTheme.color.neutralNetworkGray}
    />
    <Path
      d="M4.81188 11.7241C4.9616 11.9271 5.15572 12.0919 5.3789 12.2053C5.60208 12.3188 5.84816 12.3779 6.09768 12.3779L8.70142 12.3779C8.78171 12.3785 8.86086 12.3973 8.93301 12.4332C9.00515 12.469 9.06846 12.5209 9.11824 12.585C9.22145 12.782 9.25185 13.01 9.20396 13.2279L8.74214 15.4411C8.68408 15.6636 8.67856 15.897 8.72604 16.1222C8.77353 16.3474 8.87266 16.5579 9.01537 16.7367C9.16222 16.9059 9.3443 17.0396 9.54836 17.1282C9.75242 17.2167 9.97333 17.2579 10.195 17.2487C10.4167 17.2396 10.6336 17.1802 10.8299 17.0751C11.0262 16.9699 11.197 16.8216 11.3298 16.6408L13.9143 12.6089C13.9626 12.539 14.0268 12.4819 14.1014 12.4425C14.176 12.403 14.2588 12.3824 14.3429 12.3823L14.6782 12.3823C14.8203 12.3823 14.9566 12.3249 15.0571 12.2227C15.1575 12.1205 15.214 11.9819 15.214 11.8374L15.214 6.71146C15.2141 6.61089 15.1868 6.51225 15.1352 6.42647C15.0836 6.34069 15.0096 6.27112 14.9215 6.22546C12.4667 4.95051 11.9148 4.75 10.5873 4.75C10.2819 4.75 9.23503 4.78051 8.61035 4.78051C7.11882 4.78051 6.12983 5.65227 5.59086 7.41977L4.56865 10.289C4.49653 10.5309 4.48096 10.7867 4.52318 11.0358C4.56541 11.285 4.66427 11.5207 4.81188 11.7241Z"
      fill={props.isColor ? XyzTheme.color.brandRed : XyzTheme.color.neutralNetworkGray}
    />
  </SVG>
)

ThumbsDownIcon.defaultProps = {
  viewBox: '0 0 24 21',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

ThumbsDownIcon.propTypes = {
  isColor: PropTypes.bool.isRequired,
}

export { ThumbsDownIcon }
