import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'

const ParcelMediumPlusIcon: React.FC<DefaultIconProps> = (props) => (
  <SVG {...props}>
    <Path
      d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
      fill={XyzTheme.color.brandCardboard}
    />
    <Path
      d="M26.9908 28H22.3548V15.506L18.9705 28H13.9405L10.533 15.4133V28H5.89699V12.5853H13.8709L16.5366 22.9468L19.2023 12.5853H26.9908V28Z"
      fill={XyzTheme.color.brandPurple}
    />
    <Path
      d="M38.789 16.4824H35.3556V20.0287H32.2836V16.4824H28.8501V13.6136H32.2836V10.0673H35.3556V13.6136H38.789V16.4824Z"
      fill={XyzTheme.color.brandPurple}
    />
  </SVG>
)

ParcelMediumPlusIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelMediumPlusIcon }
