import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Circle } from '../icons.styled'
import { InfoGlyphIconDefaultProps, InfoGlyphIconProps, InfoGlyphIconPropsTypes } from '../icons.types'

export const InfoGlyphRoundIcon: React.FC<InfoGlyphIconProps> = ({
  icon: Icon,
  iconColor,
  disabled,
  backgroundColor,
  ...rest
}) => {
  return (
    <SVG fill="none" {...rest}>
      <Circle cx="16" cy="16" r="16" fill={disabled ? XyzTheme.color.neutralGray5 : backgroundColor} />
      <Icon x="23%" y="23%" width="18" height="17" color={disabled ? XyzTheme.color.neutralPassiveGray : iconColor} />
    </SVG>
  )
}

InfoGlyphRoundIcon.propTypes = InfoGlyphIconPropsTypes

InfoGlyphRoundIcon.defaultProps = {
  ...InfoGlyphIconDefaultProps,
  backgroundColor: XyzTheme.color.brandPink,
}
