/* eslint-disable import/named */
import styles from 'react-dates/lib/css/_datepicker.css'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { CalendarIcon } from '../../design-tokens/icons'
import { InputContent, inputStyle } from '../../basic-components/Input/Input.style'
import { DateInputContentProps, MonthNavigationProps, DateRangeInputArrowContainerProps } from './DateInput.types'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { iconAttrs } from '../../design-tokens/icons/icons.styled'
import { IconButton } from '../../basic-components/IconButton'
import { UIMessage } from '../../basic-components/UIMessage'

const selectedRangeBackground = (theme) => `
  background: linear-gradient(${theme.xyz.color.neutralWhite} 16%, ${theme.xyz.color.signalLightBlue} 16%, ${theme.xyz.color.signalLightBlue} 84%, ${theme.xyz.color.neutralWhite} 84%) no-repeat !important;
`

const weekHeaderLeft = (theme, daySize, numberOfMonths) => {
  let style = ''
  for (let i = 0; i < numberOfMonths; ++i) {
    style += `
      &:nth-child(${i + 1}) {
        left: calc(${daySize * (i * 7)}px + ${theme.xyz.spacing.space2 * (i * 2)}rem) !important;
      }
    `
  }
  return style
}

export const StyledButton = styled(IconButton)<MonthNavigationProps>(
  ({
    theme: {
      xyz: { spacing },
    },
    isLeft,
  }) => css`
    position: absolute;
    top: ${spacing.space3}rem;
    ${isLeft
      ? `
    left: ${spacing.space3}rem;
    `
      : `
    right: ${spacing.space3}rem;
    `}
  `
)

export const DateRangeInputArrowContainer = styled.div<DateRangeInputArrowContainerProps>(
  ({
    theme: {
      xyz: { fontFamily, color, fontWeight, fontSize },
    },
    disabled,
  }) => css`
    font-family: ${fontFamily.Montserrat};
    color: ${disabled ? color.neutralPassiveGray : color.neutralNetworkGray};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.label.three}rem;
  `
)

export const StyledCalendarIcon = styled(CalendarIcon).attrs<IconProps>(iconAttrs)<IconProps>``

export const datePickerStyle = ({
  theme,
  disabled,
  singleDatePicker,
  daySize,
  numberOfMonths,
}: DateInputContentProps): FlattenSimpleInterpolation => css`
  ${styles}

  .CalendarMonth_caption {
    padding-bottom: ${theme.xyz.spacing.space7}rem !important;
  }

  .CalendarMonth_table {
    border-collapse: separate !important;
  }

  .CalendarDay {
    border: none !important;
    font-size: ${theme.xyz.fontSize.label.three}rem !important;
    font-weight: ${theme.xyz.fontWeight.semiBold} !important;
    outline: none;
  }

  .CalendarDay__default {
    color: ${theme.xyz.color.neutralNetworkGray} !important;
  }

  .CalendarDay__blocked_out_of_range {
    color: ${theme.xyz.color.neutralPassiveGray} !important;
  }

  .CalendarDay__default.CalendarDay__selected {
    color: ${theme.xyz.color.neutralWhite} !important;
    font-weight: ${theme.xyz.fontWeight.semiBold} !important;
    position: relative;
    z-index: ${theme.xyz.zIndex.zIndex0};
    ${singleDatePicker ? 'background: none !important;' : selectedRangeBackground(theme)}

    :after {
      background: ${theme.xyz.color.signalBlue};
      border-radius: 50%;
      transform: scale(1) !important;
      transform: scale(1);
    }
  }

  .CalendarDay__selected_start {
    background-position: 24px center !important;

    // hide half of selected background
    :before {
      content: '';
      width: ${daySize / 2}px;
      height: ${daySize - 1}px;
      background-color: white;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: ${theme.xyz.zIndex.zIndexInvisible};
    }
  }

  .CalendarDay__selected_end {
    background-position: -24px center !important;

    // hide half of selected background
    :before {
      content: '';
      width: ${daySize / 2}px;
      height: ${daySize - 1}px;
      background-color: white;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      z-index: ${theme.xyz.zIndex.zIndexInvisible};
    }
  }

  .CalendarDay__selected_span {
    ${selectedRangeBackground(theme)}
    color: ${theme.xyz.color.neutralBlack} !important;
  }

  .CalendarDay__hovered_span {
    ${selectedRangeBackground(theme)}
    color: ${theme.xyz.color.neutralBlack} !important;

    :hover {
      ${selectedRangeBackground(theme)}
      background-position: -24px center !important;
    }
  }

  .CalendarDay__today:not(.CalendarDay__selected_span):not(.CalendarDay__selected) {
    font-weight: ${theme.xyz.fontWeight.semiBold} !important;
    color: ${theme.xyz.color.neutralNetworkGray} !important;
    border-radius: 50% !important;
    border: 2px solid ${theme.xyz.color.neutralPassiveGray} !important;
    background: none !important;

    :after {
      width: ${daySize - 4}px;
      height: ${daySize - 5}px;
      transform: scale(0.9);
    }

    :hover:after {
      background: none !important;
      transition: 0.2s transform ease-in;
      transform: scale(1);
    }
  }

  .CalendarDay__default {
    position: relative;
    z-index: ${theme.xyz.zIndex.zIndex0};

    :not(.CalendarDay__selected):not(.CalendarDay__hovered_span):not(.CalendarDay__blocked_calendar):not(.CalendarDay__today):not(.CalendarDay__selected_span):hover {
      background: none !important;
      border: none !important;
    }

    :after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: ${daySize}px;
      height: ${daySize - 1}px;
      border-radius: 50% !important;
      z-index: ${theme.xyz.zIndex.zIndexInvisible};
      transform: scale(0.9);
    }

    :not(.CalendarDay__blocked_out_of_range):not(.CalendarDay__blocked_calendar):not(.CalendarDay__selected) {
      :hover:after,
      :focus:after {
        background: ${theme.xyz.color.neutralGray5} !important;
        transition: 0.2s transform ease-in;
        transform: scale(1);
      }
    }
  }

  .DayPicker_weekHeader_li small {
    font-size: ${theme.xyz.fontSize.label.five}rem !important;
    font-weight: ${theme.xyz.fontWeight.medium} !important;
    color: ${theme.xyz.color.neutralBlack} !important;
  }

  .CalendarMonth_caption strong {
    font-size: ${theme.xyz.fontSize.label.two}rem !important;
    font-weight: ${theme.xyz.fontWeight.semiBold} !important;
    color: ${theme.xyz.color.neutralBlack} !important;
  }

  // remove all borders and box-shadows
  .DayPicker__withBorder {
    border: none !important;
    box-shadow: none !important;
  }

  .DateInput,
  .DateInput_input,
  .DateInput_input__focused,
  .SingleDatePickerInput,
  .SingleDatePicker,
  .SingleDatePicker_picker,
  .CalendarMonth,
  .CalendarMonthGrid,
  .DateRangePickerInput {
    background: transparent;
  }

  .SingleDatePicker,
  .SingleDatePickerInput,
  .DateInput {
    width: 100%;
  }

  .DateRangePickerInput .DateInput {
    max-width: calc(50% - ${theme.xyz.spacing.space4 / 2}rem);
  }

  .DateInput_input {
    ${inputStyle({ theme, disabled })}

    &.DateInput_input__disabled {
      font-style: normal;
    }
  }

  .SingleDatePicker_picker,
  .DateRangePicker_picker {
    top: calc(${theme.xyz.spacing.space4 + 2 * theme.xyz.spacing.space3}rem + 1px) !important;
    left: -${theme.xyz.spacing.space4}rem !important;
  }

  .DayPicker {
    border-radius: ${theme.xyz.borderRadius.md}rem;
    width: calc(${daySize * (numberOfMonths * 7)}px + ${theme.xyz.spacing.space2 * (numberOfMonths * 2)}rem) !important;

    > div > div,
    .DayPicker_transitionContainer {
      width: calc(
        ${daySize * (numberOfMonths * 7)}px + ${theme.xyz.spacing.space2 * (numberOfMonths * 2)}rem
      ) !important;
    }
  }

  .DateInput_fang {
    display: none;
  }

  .CalendarMonth,
  .DayPicker_weekHeader {
    padding: 0 ${theme.xyz.spacing.space2}rem !important;
  }

  .DayPicker_weekHeader {
    ${weekHeaderLeft(theme, daySize, numberOfMonths)}
  }

  .DayPicker_weekHeaders__horizontal {
    margin: 0;
  }

  .CalendarMonthGrid__horizontal {
    left: 0;
  }
`

export const DateInputContent = styled(InputContent)<DateInputContentProps>(datePickerStyle)

export const StyledUIMessage = styled(UIMessage)(
  ({
    theme: {
      xyz: { zIndex },
    },
  }) => css`
    position: relative;
    z-index: ${zIndex.zIndex99};
  `
)
