import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

import { XyzTheme } from '@postidigital/posti-theme'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { ArrowRightIcon } from '../../design-tokens/icons'

export enum Mode {
  primary = 'primary',
  secondary = 'secondary',
}

export enum Size {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export enum ButtonIconPosition {
  left = 'left',
  right = 'right',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Define button to be some other element/component than "button"
   */
  as?: any
  /**
   * Possible values are primary or secondary. Default is primary.
   */
  mode?: keyof typeof Mode
  /**
   * Possible values are lg, md or sm. Default is md.
   */
  size?: keyof typeof Size
  icon?: React.ComponentType<DefaultIconProps>
  iconPosition?: keyof typeof ButtonIconPosition
  iconColor?: string
  /**
   * Secondary small button can have a variation of no background
   */
  hasBackground?: boolean
}

export const buttonColors = {
  primary: {
    color: XyzTheme.color.neutralWhite,
    background: XyzTheme.color.signalBlue,
    backgroundHover: XyzTheme.color.signalHoverBlue,
    backgroundPress: XyzTheme.color.signalHoverBlue,
    backgroundDisabled: XyzTheme.color.neutralGray5,
    backgroundFocus: XyzTheme.color.signalBlue,
  },
  secondary: {
    color: XyzTheme.color.signalBlue,
    background: XyzTheme.color.neutralGray5,
    backgroundHover: XyzTheme.color.neutralOnHoverGray,
    backgroundPress: XyzTheme.color.neutralOnPressGray,
    backgroundDisabled: XyzTheme.color.neutralGray5,
    backgroundFocus: XyzTheme.color.neutralGray5,
  },
}

const padding = {
  [Size.lg]: '1.5rem 2.5rem',
  [Size.md]: '1.1875rem 2rem',
  [Size.sm]: '1rem 1.5rem',
}

const paddingWithIcon = {
  [Size.lg]: '1.5rem 2.5rem',
  [Size.md]: '1rem 2rem',
  [Size.sm]: '0.75rem 1.5rem',
}

const iconOnlyPadding = '0.25rem'

const fontSize = {
  [Size.lg]: `${XyzTheme.fontSize.label.one}rem`,
  [Size.md]: `${XyzTheme.fontSize.label.two}rem`,
  [Size.sm]: `${XyzTheme.fontSize.label.three}rem`,
}

const lineHeight = {
  [Size.lg]: XyzTheme.lineHeight.label.one,
  [Size.md]: XyzTheme.lineHeight.label.two,
  [Size.sm]: XyzTheme.lineHeight.label.three,
}

// In rems
const animationSize = 0.5

const StyledButton = styled.button<ButtonProps>(
  ({
    theme: {
      xyz: { color, zIndex },
    },
    mode,
    size,
    hasBackground,
  }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    border-style: none;
    border-radius: 3rem;
    border: 3px solid transparent;
    background-clip: padding-box;
    transition: all 100ms ease-in;
    position: relative;
    z-index: ${zIndex.zIndex0};
    color: ${buttonColors[mode].color};
    background-color: ${mode === Mode.secondary && !hasBackground && size === Size.sm
      ? 'transparent'
      : buttonColors[mode].background};
    padding: 0;

    /* Needed to scale the container but not the content */
    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: ${zIndex.zIndexInvisible};
      border-radius: 3rem;
      background-color: ${mode === Mode.secondary && !hasBackground ? 'transparent' : buttonColors[mode].background};
      transition: all 100ms ease-in;
    }

    &:hover:before {
      background-color: ${buttonColors[mode].backgroundHover};
    }

    &:active {
      &:before {
        background-color: ${buttonColors[mode].backgroundPress};
      }

      ${StyledInner} {
        transform: scale(0.97);
        transform-origin: 50% 50%;
      }
    }

    &:focus {
      outline: 0;
      border: 3px solid ${color.signalLightBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
      &:before {
        background-color: ${buttonColors[mode].backgroundFocus};
      }
    }

    &:hover:not(:focus),
    &:active:not(:focus) {
      &:before {
        top: -${animationSize / 2}rem;
        left: -${animationSize / 2}rem;
        height: calc(100% + ${animationSize}rem);
        width: calc(100% + ${animationSize}rem);
      }
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      color: ${color.neutralPassiveGray};
      background-color: ${buttonColors[mode].backgroundDisabled};

      &:before {
        content: none;
      }
    }
  `
)

const StyledInner = styled.span<Pick<ButtonProps, 'size' | 'mode'>>`
  ${({
    theme: {
      xyz: { fontFamily, fontWeight },
    },
    size,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: inherit;
    transition: all 100ms ease-in;
    width: 100%;
    font-family: ${fontFamily.Montserrat};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize[size]};
    line-height: ${lineHeight[size]};
  `}
`

const FocusInner = styled.span<Pick<ButtonProps, 'size'> & { hasIcon: boolean; isOnlyIcon: boolean }>`
  ${({ size, hasIcon, isOnlyIcon }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: inherit;
    transition: all 100ms ease-in;
    padding: ${hasIcon ? paddingWithIcon[size] : padding[size]};

    ${isOnlyIcon &&
    css`
      padding: ${iconOnlyPadding};
    `}
  `}
`

type IconProps = Pick<ButtonProps, 'iconPosition' | 'size' | 'mode'> & { isOnlyIcon: boolean }

const StyledIcon = styled(ArrowRightIcon)<IconProps>`
  ${({
    theme: {
      xyz: { iconSize, spacing },
    },
    iconPosition,
    size,
    isOnlyIcon,
  }) => css`
    flex-shrink: 0;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;

    ${!isOnlyIcon &&
    iconPosition === ButtonIconPosition.left &&
    css`
      margin-right: ${size === Size.lg ? spacing.space4 : spacing.space3}rem;
    `}
    ${!isOnlyIcon &&
    iconPosition === ButtonIconPosition.right &&
    css`
      margin-left: ${size === Size.lg ? spacing.space4 : spacing.space3}rem;
    `}
  `}
`

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    icon: Icon,
    disabled,
    mode,
    iconPosition,
    size,
    iconColor: customIconColor,
    hasBackground,
    ...rest
  } = props
  const iconColor = disabled ? XyzTheme.color.neutralPassiveGray : customIconColor || buttonColors[mode].color
  const isOnlyIcon = !React.Children.count(children) && !!Icon

  return (
    <StyledButton
      ref={ref}
      aria-disabled={disabled}
      disabled={disabled}
      mode={mode}
      size={size}
      hasBackground={hasBackground}
      tabIndex={0}
      {...rest}
    >
      <FocusInner hasIcon={!!Icon} isOnlyIcon={isOnlyIcon} size={size} tabIndex={-1}>
        {!!Icon && iconPosition === ButtonIconPosition.left && (
          <StyledIcon
            as={Icon}
            iconPosition={iconPosition}
            color={iconColor}
            size={size}
            mode={mode}
            isOnlyIcon={isOnlyIcon}
            aria-hidden={true}
          />
        )}
        <StyledInner size={size} mode={mode}>
          {children}
        </StyledInner>
        {!!Icon && iconPosition === ButtonIconPosition.right && (
          <StyledIcon
            as={Icon}
            iconPosition={iconPosition}
            color={iconColor}
            size={size}
            mode={mode}
            isOnlyIcon={isOnlyIcon}
            aria-hidden={true}
          />
        )}
      </FocusInner>
    </StyledButton>
  )
})

Button.displayName = 'Button'

Button.propTypes = {
  mode: PropTypes.oneOf(Object.values(Mode)),
  size: PropTypes.oneOf(Object.values(Size)),
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconPosition: PropTypes.oneOf(Object.values(ButtonIconPosition)),
  iconColor: PropTypes.string,
}

Button.defaultProps = {
  mode: Mode.primary,
  size: Size.md,
  iconPosition: ButtonIconPosition.left,
}

export default Button
