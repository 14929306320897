import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path, Rect, G } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import PropTypes from 'prop-types'

interface Props extends DefaultIconProps {
  backgroundColor?: string
}

const PostiLogo: React.FC<Props> = ({ backgroundColor, color, ...props }) => (
  <SVG {...props}>
    <G clipPath="url(#clip0)">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="10" width="24" height="12">
        <Path d="M4.40002 10.8H27.65V21.9H4.40002V10.8Z" fill={color} />
      </mask>
      <G mask="url(#mask0)">
        <Path
          fillRule="evenodd"
          d="M20.9 15.8099C20.6 15.6299 20.3 15.5099 19.97 15.3899C19.4 15.2099 19.16 15.0599 19.16 14.7299C19.16 14.4599 19.4 14.2799 19.76 14.2799C20.12 14.2799 20.48 14.3999 20.78 14.6999L21.74 13.6799C21.17 13.1699 20.6 12.8699 19.79 12.8699C18.95 12.8699 17.81 13.5299 17.81 14.7599C17.81 15.1799 17.96 15.5099 18.17 15.7799H20.9V15.8099ZM23 15.8099H24.44V14.2499H25.76V12.9899H24.44V10.7999H23V12.9899H21.92V14.2499H23V15.8099ZM26.21 15.8099H27.65V12.9899H26.21V15.8099ZM16.07 16.1699C16.04 17.1299 15.35 17.8499 14.39 17.8499C13.43 17.8499 12.74 17.1599 12.71 16.1699H11.24C11.3 17.8799 12.65 19.2299 14.39 19.2299C16.13 19.2299 17.48 17.8799 17.54 16.1699H16.07ZM21.32 16.1699H18.47C18.77 16.3799 19.25 16.6199 19.55 16.7399C20.15 16.9799 20.36 17.0999 20.36 17.3999C20.36 17.6699 20.03 17.8499 19.64 17.8499C19.16 17.8499 18.83 17.6699 18.47 17.3699L17.51 18.3299C18.08 18.8999 18.65 19.2599 19.64 19.2599C20.72 19.2599 21.8 18.5099 21.8 17.3699C21.8 16.8299 21.62 16.4399 21.32 16.1699ZM23 19.1099H24.44V16.1699H23V19.1099ZM26.18 19.1099H27.6203V16.1699H26.18V19.1099ZM5.87002 15.8099C5.96002 14.8799 6.65002 14.2799 7.58002 14.2799C8.51002 14.2799 9.20002 14.9099 9.29002 15.8099H10.76C10.67 14.1299 9.38002 12.8699 7.70002 12.8699C6.89002 12.8699 6.26002 13.1699 5.87002 13.5599H5.84002V12.9899H4.40002V15.8099H5.87002ZM9.29002 16.1699C9.26002 17.1299 8.54002 17.8499 7.58002 17.8499C6.62002 17.8499 5.90002 17.1599 5.87002 16.1699H4.40002V21.8999H5.84002V18.5399H5.87002C6.26002 18.9299 6.83002 19.2299 7.64002 19.2299C9.38002 19.2299 10.7 17.9399 10.76 16.1699H9.29002ZM12.68 15.8099C12.77 14.9099 13.43 14.2799 14.36 14.2799C15.29 14.2799 15.95 14.9099 16.04 15.8099H17.51C17.39 14.1599 16.04 12.8699 14.36 12.8699C12.68 12.8699 11.33 14.1599 11.21 15.8099H12.68Z"
          fill={color}
        />
      </G>
      <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="25" y="10" width="4" height="3">
        <Path d="M25.2798 10.86H28.4V12.75H25.2798V10.86Z" fill={color} />
      </mask>
      <G mask="url(#mask1)">
        <Path
          fillRule="evenodd"
          d="M25.9701 12.15C26.1501 12.3 26.3001 12.42 26.4501 12.42C26.5101 12.42 26.5701 12.39 26.6001 12.36C26.2701 12.27 26.0601 11.97 26.0601 11.73C26.0601 11.49 26.2398 11.31 26.4798 11.31C26.6298 11.31 26.7798 11.37 26.8398 11.49C26.9298 11.1 27.1701 10.86 27.4398 10.86C27.6798 10.86 27.8001 11.04 27.8001 11.31C27.8001 11.64 27.5001 12.15 27.1398 12.36C27.1701 12.42 27.2601 12.45 27.3501 12.45C27.6501 12.45 27.9501 12.18 28.1298 11.94C28.1601 11.91 28.2201 11.88 28.2501 11.88C28.3398 11.88 28.4001 11.94 28.4001 12.03C28.4001 12.06 28.4001 12.09 28.3701 12.15C28.1898 12.36 27.8298 12.75 27.3798 12.75C27.1701 12.75 27.0201 12.66 26.9298 12.51C26.8101 12.63 26.6601 12.75 26.4501 12.75C26.2398 12.75 26.0298 12.6 25.8501 12.48C25.7601 12.42 25.6701 12.33 25.6101 12.3C25.5501 12.24 25.4898 12.21 25.4601 12.21C25.3398 12.21 25.2798 12.15 25.2798 12.06C25.2798 11.97 25.3398 11.91 25.4601 11.91C25.5201 11.91 25.6101 11.94 25.7001 12C25.7898 12 25.8798 12.06 25.9701 12.15ZM26.4798 11.61C26.3898 11.61 26.3601 11.67 26.3601 11.73C26.3601 11.91 26.5701 12.06 26.7798 12.09V12.03C26.7798 11.82 26.6601 11.61 26.4798 11.61ZM27.4101 11.13C27.2898 11.13 27.0798 11.31 27.0798 12C27.2898 11.82 27.5001 11.46 27.5001 11.25C27.5298 11.19 27.5001 11.13 27.4101 11.13Z"
          fill={color}
        />
      </G>
    </G>
    <defs>
      <clipPath id="clip0">
        <Rect width="24" height="11.1" fill="white" transform="translate(4.40002 10.8)" />
      </clipPath>
    </defs>
  </SVG>
)

PostiLogo.defaultProps = {
  viewBox: '0 0 32 32',
  height: `${XyzTheme.iconSize.xl}rem`,
  width: `${XyzTheme.iconSize.xl}rem`,
}

PostiLogo.propTypes = {
  backgroundColor: PropTypes.string,
}

export { PostiLogo }
