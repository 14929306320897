import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { AlertIndicatorIcon, CheckmarkIndicatorIcon } from '../../design-tokens/icons'
import { Body, BodySize } from '../../design-tokens/typography'

export interface UIMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  success?: boolean
  message: string
}

const MessageContainer = styled.div`
  display: flex;
  margin-top: ${XyzTheme.spacing.space2}rem;
  align-items: center;

  & > svg {
    flex-shrink: 0;
  }
`

export const StyledBody = styled(Body)(
  ({ color }) => css`
    margin-left: ${XyzTheme.spacing.space2}rem;
    color: ${color};
    margin-bottom: 0;
  `
)

export const UIMessage: React.FC<UIMessageProps> = ({ success, message, ...rest }) => {
  const Icon = success ? CheckmarkIndicatorIcon : AlertIndicatorIcon
  const color = success ? XyzTheme.color.signalGreen : XyzTheme.color.signalRed
  const iconProps = {
    width: `${XyzTheme.iconSize.xs}em`,
    height: `${XyzTheme.iconSize.xs}em`,
    color,
  }

  return (
    <MessageContainer {...rest}>
      <Icon {...iconProps} aria-hidden="true"></Icon>
      <StyledBody size={BodySize.Five} color={color}>
        {message}
      </StyledBody>
    </MessageContainer>
  )
}

UIMessage.defaultProps = {
  success: false,
}

UIMessage.propTypes = {
  success: PropTypes.bool,
  message: PropTypes.string.isRequired,
}
