import React from 'react'
import { DateRangeInputArrowContainer } from './DateInput.style'
import { DateRangeInputArrowProps } from './DateInput.types'

const arrow = '\u00a0\u2013\u00a0'

export const DateRangeInputArrow: React.FC<DateRangeInputArrowProps> = ({ hasValue, disabled, ...props }) => {
  if (!hasValue) {
    return <></>
  }
  return (
    <DateRangeInputArrowContainer disabled={disabled} {...props}>
      {arrow}
    </DateRangeInputArrowContainer>
  )
}
